"use client";
import { logger } from "@/lib/logging/logger";
import React, { useEffect, useState } from "react";
import "@/custom.css";
import clsx from "clsx";
import { gtag } from "./GoogleTagManager";
import { useTranslation } from "react-i18next";
import { Switch } from "./ui/switch";

export default function CookieBanner() {
  const [hidden, setHidden] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const [preferences, setPreferences] = useState({
    necessary: true,
    analytics: true,
    advertising: true,
    personalization: true,
  });

  const { t } = useTranslation();

  useEffect(() => {
    try {
      const isCookieConsent = localStorage.getItem("cookie-consent");
      setHidden(!!isCookieConsent);
    } catch (err) {
      logger.error(err);
    }
  }, []);

  const saveConsent = (newPreferences: typeof preferences) => {
    try {
      const consent = {
        analytics_storage: newPreferences.analytics ? "granted" : "denied",
        ad_storage: newPreferences.advertising ? "granted" : "denied",
        ad_personalization: newPreferences.personalization
          ? "granted"
          : "denied",
        ad_user_data: newPreferences.advertising ? "granted" : "denied",
      };

      gtag("consent", "update", consent);
      localStorage.setItem("cookie-consent", JSON.stringify(consent));
      setHidden(true);
      setPreferences(newPreferences);
    } catch (err) {
      logger.error(err);
    }
  };

  const handleDeclineAll = () => {
    const newPreferences = {
      ...preferences,
      analytics: false,
      advertising: false,
      personalization: false,
    };
    saveConsent(newPreferences);
  };

  const handleAcceptAll = () => {
    const newPreferences = {
      ...preferences,
      analytics: true,
      advertising: true,
      personalization: true,
    };
    saveConsent(newPreferences);
  };

  const handleSavePreferences = () => {
    saveConsent(preferences);
  };

  const style = clsx(
    `z-[9999] fixed top-0 bottom-0 w-screen bg-black bg-opacity-20 flex items-center justify-center`,
    hidden && "hidden",
  );

  return (
    <div className={style}>
      <div className="flex flex-col max-w-[calc(min(95%,800px))] max-h-[80%] overflow-y-auto gap-6 sm:gap-8 bg-white px-4 py-6 rounded-lg">
        <div className="flex flex-col gap-5">
          <div className="text-center font-semibold text-lg">
            {t("cookieBanner.title")}
          </div>
          <div className="text-left">{t("cookieBanner.description")}</div>
        </div>

        {showMore && (
          <div className="flex flex-col gap-4">
            {/* Necessary Cookies */}
            <div className="flex items-center justify-between py-2 border-b">
              <div>
                <div className="font-medium">
                  {t("cookieBanner.necessaryTitle")}
                </div>
                <div className="text-sm text-gray-500">
                  {t("cookieBanner.necessaryDesc")}
                </div>
              </div>
              <Switch checked={true} disabled />
            </div>

            {/* Analytics Cookies */}
            <div className="flex items-center justify-between py-2 border-b">
              <div>
                <div className="font-medium">
                  {t("cookieBanner.analyticsTitle")}
                </div>
                <div className="text-sm text-gray-500">
                  {t("cookieBanner.analyticsDesc")}
                </div>
              </div>
              <Switch
                checked={preferences.analytics}
                onCheckedChange={(checked) =>
                  setPreferences((prev) => ({ ...prev, analytics: checked }))
                }
              />
            </div>

            {/* Advertising Cookies */}
            <div className="flex items-center justify-between py-2 border-b">
              <div>
                <div className="font-medium">
                  {t("cookieBanner.advertisingTitle")}
                </div>
                <div className="text-sm text-gray-500">
                  {t("cookieBanner.advertisingDesc")}
                </div>
              </div>
              <Switch
                checked={preferences.advertising}
                onCheckedChange={(checked) =>
                  setPreferences((prev) => ({ ...prev, advertising: checked }))
                }
              />
            </div>

            {/* Personalization Cookies */}
            <div className="flex items-center justify-between py-2 border-b">
              <div>
                <div className="font-medium">
                  {t("cookieBanner.personalizationTitle")}
                </div>
                <div className="text-sm text-gray-500">
                  {t("cookieBanner.personalizationDesc")}
                </div>
              </div>
              <Switch
                checked={preferences.personalization}
                onCheckedChange={(checked) =>
                  setPreferences((prev) => ({
                    ...prev,
                    personalization: checked,
                  }))
                }
              />
            </div>
          </div>
        )}

        <div className="flex flex-col-reverse justify-stretch sm:flex-row sm:justify-end sm:items-stretch gap-4 sm:gap-2">
          <div
            onClick={handleDeclineAll}
            className="hover:cursor-pointer hover:bg-slate-100 flex sm:w-40 bg-white justify-center items-center p-1 sm:p-2 rounded-lg border-black ring-1 ring-black text-center cursor-pointer"
          >
            {t("cookieBanner.decline")}
          </div>

          <div
            onClick={() =>
              showMore ? handleSavePreferences() : setShowMore(true)
            }
            className="hover:cursor-pointer hover:bg-blue-700 flex sm:w-40 bg-blue-600 justify-center items-center p-1 sm:p-2 rounded-lg text-white text-center cursor-pointer"
          >
            {showMore
              ? t("cookieBanner.acceptSel")
              : t("cookieBanner.showMore")}
          </div>

          <div
            onClick={handleAcceptAll}
            className="hover:cursor-pointer hover:bg-blue-700 flex sm:w-40 bg-blue-600 justify-center items-center p-1 sm:p-2 rounded-lg text-white text-center cursor-pointer"
          >
            {t("cookieBanner.acceptAll")}
          </div>
        </div>
      </div>
    </div>
  );
}
