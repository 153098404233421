"use client";

import { usePathname, useSearchParams } from "next/navigation";
import Script from "next/script";
import { useEffect } from "react";

// load google tag manager scripts according to user consent

interface GoogleTagManagerProps {
  googleTagId: string;
  userId: string | undefined;
  isInternal: boolean | undefined;
}

const GoogleTagManager: React.FC<GoogleTagManagerProps> = ({
  googleTagId,
  userId,
  isInternal,
}) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    const url = `${pathname}?${searchParams}`;

    // fire first pageview which sometimes does not fire because script is set to afterInteractive
    gtag("event", "pageview", {
      page_location: url,
      page_path: pathname,

      // Capture referral source
      page_referrer: document.referrer || "direct",

      // UTM parameters for tracking ad/campaign sources
      utm_source: searchParams.get("utm_source") || "",
      utm_medium: searchParams.get("utm_medium") || "",
      utm_campaign: searchParams.get("utm_campaign") || "",
      utm_term: searchParams.get("utm_term") || "",
      utm_content: searchParams.get("utm_content") || "",
    });

    // track users across devices
    if (userId && typeof isInternal === "boolean") {
      gtag("set", {
        user_id: userId,
        Arnia_traffic: isInternal ? "1" : "0",
      });
      gtag("set", "user_properties", {
        Arnia_traffic: isInternal ? "1" : "0",
      });
    }
  }, [userId, isInternal, googleTagId]);

  if (
    process.env.NODE_ENV === "development" ||
    process.env.VERCEL_ENV !== "production"
  ) {
    return null;
  }

  return (
    <>
      <Script
        id="google-tag-manager-consent"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
         window.dataLayer = window.dataLayer || [];

        window.gtag = function () {
            dataLayer.push(arguments);
        }

        if (localStorage.getItem('cookie-consent') === null) {
            gtag('consent', 'default', {
                'analytics_storage': 'denied',
                'ad_storage': 'denied',
                'ad_personalization': 'denied',
                'ad_user_data': 'denied',
            });
        } else {
            gtag('consent', 'default', JSON.parse(localStorage.getItem('cookie-consent')));
        }
        `,
        }}
      />
      <Script
        id="google-tag-manager"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${googleTagId}');`,
        }}
      />
    </>
  );
};

export const gtag = (...args: any[]) => {
  if (typeof window !== "undefined") {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    if (window.gtag) {
      window.gtag(...args);
    } else {
      window.dataLayer.push(args);
    }
  }
};

export default GoogleTagManager;
