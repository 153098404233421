"use client";
import { usePathname } from "next/navigation";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { getPathWithoutLocale } from "./translationUtils";
import { useTranslation } from "react-i18next";
import { Noto_Color_Emoji } from "next/font/google";

const emojiFont = Noto_Color_Emoji({
  subsets: ["emoji"],
  weight: "400",
});

interface Language {
  code: string;
  flag: string;
}

const languages: Language[] = [
  {
    code: "En",
    flag: "🇬🇧",
  },
  {
    code: "Ro",
    flag: "🇷🇴",
  },
];

export default function LanguageChanger({
  showOnTop = false,
}: {
  showOnTop?: boolean;
}) {
  const currentPathname = usePathname();
  const { t } = useTranslation("common");

  const currentLocale =
    languages.find((lang) =>
      currentPathname.startsWith(`/${lang.code.toLowerCase()}`),
    )?.code || "en";

  const currentLanguage =
    languages.find((lang) => lang.code === currentLocale) || languages[0];

  const handleChange = (newLocale: string) => {
    if (newLocale === currentLocale) return;

    const cookieOptions = {
      path: "/",
      sameSite: "strict",
      maxAge: 30 * 24 * 60 * 60, // 30 days
    };

    document.cookie = `NEXT_LOCALE=${newLocale}; path=${cookieOptions.path}; SameSite=${cookieOptions.sameSite}; max-age=${cookieOptions.maxAge}`;

    const newPath = getPathWithoutLocale(currentPathname);
    const finalPath = `/${newLocale}${newPath}`;

    // Use window location else things get cached and behave weirdly
    window.location.href = finalPath;
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className="flex items-center gap-2 px-3 py-2 text-sm rounded-md border"
        aria-label={t("currentLanguage", { current: currentLanguage.code })}
      >
        <span className={emojiFont.className}>{currentLanguage.flag}</span>
        <span>{currentLanguage.code}</span>
      </DropdownMenuTrigger>
      <DropdownMenuContent side={showOnTop ? "top" : "bottom"}>
        {languages.map((language) => (
          <DropdownMenuItem
            key={language.code}
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => handleChange(language.code.toLowerCase())}
            aria-label={t("switchTo", { new: language.code })}
          >
            <span className={emojiFont.className}>{language.flag}</span>
            <span>{t(language.code)}</span>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
