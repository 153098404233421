"use client";
import { useEffect, useState } from "react";
import {
  fetchPricingItems,
  usePricingItemsStore,
} from "@/app/[locale]/(dashboard)/stores/pricingItemsStore";
import { usePricingStore } from "@/app/[locale]/(dashboard)/stores/pricingStore";
import { useTranslation } from "react-i18next";

export const usePricingItemsFetch = () => {
  //responsible for data about prices
  const pricingStore = usePricingStore();

  //responsible for data about frontend plan descriptions, stored in db
  const pricingItemsStore = usePricingItemsStore();
  const { pricingItems } = pricingItemsStore;

  const { i18n } = useTranslation();

  const [isHydrated, setIsHydrated] = useState(false);
  useEffect(() => {
    if (!isHydrated) {
      setIsHydrated(true);
      return;
    }
    if (!pricingStore.plans) return;
    const fetchPricingItemsIntoStoreState = async () => {
      if (!pricingItems) {
        const { pricingItems } = await fetchPricingItems(
          pricingStore,
          i18n.language,
        );
        pricingItemsStore.setPricingItems(pricingItems);
      }
    };
    fetchPricingItemsIntoStoreState();
  }, [pricingStore.plans, pricingItems, isHydrated]);

  return {
    pricingItems,
  };
};
