import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/CanonicalLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/CookieBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/GoogleTagManager.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/mobile-navbar/MobileNavbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/providers/AuthContext.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/providers/config-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ModalProvider"] */ "/vercel/path0/components/providers/modal-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProvidesTheQueryClient"] */ "/vercel/path0/components/providers/query-client-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserContextProvider"] */ "/vercel/path0/components/providers/user-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/Stores/PricingFetcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/lib/i18n/TranslationsProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/simplebar-react/dist/simplebar.min.css");
