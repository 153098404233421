"use client";
import {
  fetchPlans,
  usePricingStore,
} from "@/app/[locale]/(dashboard)/stores/pricingStore";
import { useSession } from "next-auth/react";
import { useEffect, useState } from "react";

export default function usePricingFetch() {
  const { plans, setPlans } = usePricingStore();
  const session = useSession();
  const [isHydrated, setIsHydrated] = useState(false);

  useEffect(() => {
    if (!isHydrated) {
      setIsHydrated(true);
      return;
    }

    const fetchIntoState = async () => {
      if (!plans) {
        const { plans } = await fetchPlans();
        setPlans(plans);
      }
    };
    fetchIntoState();
  }, [session.data, plans, isHydrated]);
}
