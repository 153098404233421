import i18nConfig from "@/i18nConfig";
import { TFunction } from "i18next";
import { FieldErrors, FieldValues } from "react-hook-form";

type UnsupportedLanguages = {
  [key: string]: {
    [key: string]: string;
  };
};

const intlUnsupportedLanguages: UnsupportedLanguages = {
  scn: { en: "Sicilian", ro: "Siciliană", scn: "Sicilian" },
};

export const getPathWithoutLocale = (pathname: string) => {
  const localePattern = i18nConfig.locales.join("|");
  return pathname.replace(new RegExp(`^\/(?:${localePattern})`), "");
};

export const getLocalizedLanguageName = (
  locale: string,
  languageCode: string,
) => {
  const displayNames = new Intl.DisplayNames([locale], { type: "language" });
  const localizedName = displayNames.of(languageCode) || languageCode;
  if (
    localizedName === languageCode &&
    intlUnsupportedLanguages[languageCode]
  ) {
    return intlUnsupportedLanguages[languageCode][locale];
  }
  return localizedName;
};

// Function to translate form errors
export function useTranslatedErrors<T extends FieldValues>(
  errors: FieldErrors<T>,
  t: TFunction,
): FieldErrors<T> {
  if (!errors) return errors;

  const translatedErrors = { ...errors };

  Object.keys(translatedErrors).forEach((key) => {
    const field = translatedErrors[key as keyof typeof translatedErrors];

    if (field?.message) {
      const translatedMessage = t(`${field.message}`, {
        defaultValue: field.message,
        ns: "zodErrors",
      });

      field.message = translatedMessage;
    }
  });

  return translatedErrors;
}
