"use client";
import { useEffect, useState } from "react";

import { useLoadingModal } from "@/hooks/useLoadingModal";
import { useTranslation } from "react-i18next";

interface LoadingModalProps {}

export const LoadingModal = ({}: LoadingModalProps) => {
  const loadingModal = useLoadingModal();
  //   const [loading, setLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const { t } = useTranslation("common");

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return false;
  }

  if (!loadingModal.isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-50"
      role="dialog"
      aria-modal="true"
      onClick={(e) => e.stopPropagation()}
    >
      <div
        className="bg-white p-6 rounded-lg"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="loader flex justify-center items-center">
          <div className="w-24 h-24 relative">
            <div className="w-24 h-24 absolute rounded-full border-8 border-green-500 animate-spin" />
            <div className="w-24 h-24 absolute rounded-full border-8 border-white border-t-transparent animate-spin" />
          </div>
        </div>
        <p className="mt-4 text-black">{t("processing")}</p>
      </div>
    </div>
  );
};
