"use client";

import { useEffect, useState } from "react";

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useProModal } from "@/hooks/useProModal";
import Link from "next/link";
import { GoldPlanViewOnly } from "@/app/[locale]/(dashboard)/pricing/components/GoldPlanCard";
import { TooltipProvider } from "./ui/tooltip";
import { PlatinumPlanViewOnly } from "@/app/[locale]/(dashboard)/pricing/components/PlatinumPriceCard";
import { DiamondPlanViewOnly } from "@/app/[locale]/(dashboard)/pricing/components/DiamondPlanCard";
import SwiperForProModal from "./SwiperForProModal";
import { usePricingItemsStore } from "@/app/[locale]/(dashboard)/stores/pricingItemsStore";
import { usePricingStore } from "@/app/[locale]/(dashboard)/stores/pricingStore";
import { PlanCardType } from "@/app/[locale]/(dashboard)/pricing/types/types";
import axios from "axios";
import { SectionTitle } from "./landing/SectionTitle";
import { useTranslation } from "react-i18next";

export const ProModal = () => {
  const proModal = useProModal();
  const { t } = useTranslation("common");
  const { pricingItems } = usePricingItemsStore();
  const pricingStore = usePricingStore();
  const [activeSbPlan, setActiveSbPlan] =
    useState<PlanCardType["activeSubscriptionPlan"]>();

  useEffect(() => {
    const fetch = async () => {
      const userSubscriptionPlan = await axios.get(`/api/subscriptions`);
      setActiveSbPlan(userSubscriptionPlan as any);
    };
    fetch();
  }, []);
  if (!pricingItems) return null;
  return (
    <Dialog open={proModal.isOpen} onOpenChange={proModal.onClose}>
      <DialogContent className="flex flex-col h-screen sm:max-h-max max-w-none 2xl:max-w-[80rem] bg-slate-100 ">
        <DialogHeader>
          <DialogTitle className="flex justify-center items-center flex-col gap-y-4 pb-2">
            <div className="-mt-8 mb-4">
              <SectionTitle
                preTitle={t("creditsModal.preTitle")}
                title={t("creditsModal.title")}
              />
            </div>
          </DialogTitle>
        </DialogHeader>
        <TooltipProvider>
          <SwiperForProModal
            elements={[
              <GoldPlanViewOnly
                pricingItems={pricingItems}
                activeSbPlan={activeSbPlan}
                pricingStore={pricingStore}
              />,
              <PlatinumPlanViewOnly
                pricingItems={pricingItems}
                activeSbPlan={activeSbPlan}
                pricingStore={pricingStore}
              />,
              <DiamondPlanViewOnly
                pricingItems={pricingItems}
                activeSbPlan={activeSbPlan}
                pricingStore={pricingStore}
              />,
            ]}
          />
        </TooltipProvider>

        <DialogFooter>
          <div className="mx-auto items-center text-muted-foreground ">
            <Link href="/pricing" onClick={proModal.onClose}>
              <div className="h-[42px] px-4 py-3 hover:bg-slate-100 bg-white rounded-lg border border-brand-secondary justify-center items-center gap-1 inline-flex">
                <div className="text-brand-secondary  text-sm font-semibold leading-tight">
                  {t("creditsModal.proceed")}
                </div>
              </div>
            </Link>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
